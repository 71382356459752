import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const MxPalletPage = () => (
    <Layout>
        <SEO title="MxPallet" keywords={[`Nesting`, `Production Environment`, `react` ]} />
        <div className="BlueGradient">
        <div className="--headerClear"></div>
            <section className="--sectionPaceM --centerMargin --maxwidthM --edgePadding --centerTxt --heroAnimation ">
                <h2><span>MxPallet</span> expedites part sorting</h2>
                <p>Take advantage of unattended laser cutting and speed parts through the shop.</p>
            </section>
        </div>
        <section className="--centerMargin --maxwidthL">
      <article id="--productOverview">
        <h5>Product Overview</h5>
        <p className="DarkP">You’ve run jobs all night. You have five pallets, 60 work orders, multiple nests.  Now what?  Keep setting pallets to the side until someone can break them up looking for parts? The time savings of unattended laser production are easily lost if parts can’t be sorted and moved quickly. Paperwork is replaced with simple instructions that identify stacks of cut sheets and the parts you need. Shop personnel can quickly and efficiently process parts and speed production to the next operation.  Put your batch production on track and stop stacking pallets.</p>
      </article>
    </section>
    <div className="--featImg" id="featMX"></div>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid5050">
    <article className="--maxwidthS">
      <h5>Track cut sheets that need to be de-nested.</h5>
      <p className="DarkP">MxPallet tracks your sheet and part information, records it, and assigns unique pallet identification numbers that are traceable. </p>
    </article>
    <article className="--maxwidthS">
      <h5>Control batch processed jobs like they’re a single sheet.</h5>
      <p className="DarkP">Shop operators know what parts require sorting first and can quickly respond to change when necessary. With no more lost production wading through stacks of parts, MxPallet lets you take advantage of unattended laser cutting and speed parts through your shop. </p>
    </article>
    </section>
    <section className="--sectionPace --centerTxt">
      <h2 className="DarkH">Expand MxPallet with additional modules</h2>
      <p className="DarkP">Customize and control your suite of Mx products based on your shop needs. </p>
      <div className="--headerClear"></div>
      <div className="MXproducts" id="MxProductPage">
        <Link to="/mx"><span><h4>MxManager</h4></span></Link>
          <Link to="/mxmrp"> 
            <div id="ProductChip">
              <h4>MxMRP</h4>
              <hr></hr>
              <p>Real-Time Monitoring</p>
            </div>
          </Link>
          <Link to="/mxpallet"> 
            <div id="ProductChip">
              <h4>MxPallet</h4>
              <hr></hr>
              <p>Expedite finished pallets</p>
            </div>
          </Link>
          <Link to="/mxticket"> 
            <div id="ProductChip">
              <h4>MxTicket</h4>
              <hr></hr>
              <p>Smarter Labels & Tickets</p>
            </div>
          </Link>
          <Link to="/mxstation"> 
            <div id="ProductChip">
              <h4>MxStation</h4>
              <hr></hr>
              <p>Process Queue Control</p>
            </div>
          </Link>
          <Link to="/mxreport"> 
            <div id="ProductChip">
              <h4>MxReport</h4>
              <hr></hr>
              <p>Pinpoint Documentation</p>
            </div>
          </Link>
        </div>
      </section>
      <section className="--maxwidthL --edgePadding --sectionPace --centerMargin">
      <article className="--maxwidthS --centerMargin --centerTxt">
          <h2 className="DarkH">Would you like a demo?</h2>
          <p className="DarkP">Let us walk you through this software and answer your questions in a one-on-one webinar.</p>
        </article>
        <div className="--headerClear"></div>
        <section className="--maxwidthM --grid5050 --centerMargin --alignCenter">
        <a href="tel:9527465125" className="--bkgContainer --alignCenter --gridGapS" id="contactLinks">
            <div id="phoneIcon"></div>
            <article className="--grid">
              <span className="--flex --alignCenter --gridGapS"><h5 className="DarkH">Call Us</h5><p class="DarkP --margin0">7am–5pm CST</p></span>
              <p class="DarkP --margin0">(952) 746-5125</p>
            </article>
          </a>
          <a href="mailto:info@ncell.com" className="--bkgContainer --justifyLeft --gridGapS" id="contactLinks">
            <div id="emailIcon"></div>
            <article>
              <h5 className="DarkH">Email Us</h5>
              <p class="DarkP --margin0">info@ncell.com</p>
            </article>
          </a>
          </section>
          </section>
    </Layout>
)

export default MxPalletPage